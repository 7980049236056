import React from "react";
import Lottie from "lottie-react";
import Loading from "../../assets/lottie/times-up.json";
type Props = {};

const TimesUp = (props: Props) => {
  return (
    <div className="flex justify-center items-center w-full h-[100vh] bg-[black] flex-col">
      <Lottie
        animationData={Loading}
        loop={true}
        style={{ width: "80%", height: "50%" }}
      />
      <h2 className="text-white font-semibold">Task Closed</h2>
      <div>
        <p className="text-white text-center">
          Your remaining time in this task has finished
        </p>
      </div>
    </div>
  );
};

export default TimesUp;
