import Axios from "axios";

const APIRequest = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    // "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: 0,
  },
});

export default APIRequest;
