import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import Loading from "../assets/lottie/loading.json";
import { getTask } from "../api/ApiAssessment";
import { File_data } from "../contexts/CodeContext";
type Props = {};

const Auth = (props: Props) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { codeContext, setCodeContext } = React.useContext(File_data);
  const [state, setState] = React.useState("loading");

  React.useEffect(() => {
    // console.log(token);
    getTaskData();
  }, []);

  const getTaskData = async () => {
    try {
      const response = await getTask(token);
      console.log("%cAuth.tsx line:23 response", "color: #007acc;", response);
      if (response?.data?.message == "NotVerified") {
        console.log("not verified");
        navigate("/notverified");
        return;
      }
      if (response?.data?.message == "Times Up") {
        navigate("/timesup");
        return;
      }

      localStorage.setItem("token", token ? token : "notvalid");

      if (
        response.data["assessment"] &&
        localStorage.getItem(`${response.data?.assessment?.uniqe_id}`)
      ) {
        console.log("has data saved");
        let newObject = response.data;
        newObject["assessment"]["reset"] = newObject["assessment"]["baru"];
        newObject["assessment"]["baru"] = localStorage.getItem(
          `${response?.data?.assessment?.uniqe_id}`
        );

        setCodeContext(newObject);
        navigate("/");
      } else {
        console.log("no data saved");

        setCodeContext(response?.data);
        navigate("/");
      }

      console.log(response);
    } catch (error: any) {
      console.log(error);

      if (error?.response?.data?.message == "NotVerified") {
        navigate("/notverified");
        return;
      }

      if (error?.response?.data?.message == "Times Up") {
        navigate("/timesup");
        return;
      }
    }
  };

  if (state == "loading") {
    return (
      <div className="w-[100vw] h-[100vh] bg-black flex justify-center items-center">
        <Lottie
          animationData={Loading}
          loop={true}
          style={{ width: "50%", height: "50%" }}
        />
      </div>
    );
  }

  return <div className="w-[100vw] h-[100vh] bg-black">Auth</div>;
};

export default Auth;
