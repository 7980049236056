import React from "react";
import { Route, Routes } from "react-router-dom";
import Compiler from "../pages/compiler/Compiler";
import TimesUp from "../pages/notification/TimesUp";
import NotVerified from "../pages/notification/NotVerified";
import Finish from "../pages/notification/Finish";
import Auth from "../pages/Auth";
import Review from "../pages/review/Review";

type Props = {};

const CompilerRouter = (props: Props) => {
  return (
    <Routes>
      <Route path="/" element={<Compiler />} />
      <Route path="/timesup" element={<TimesUp />} />
      <Route path="/notverified" element={<NotVerified />} />
      <Route path="/finish" element={<Finish />} />
      <Route path="/auth/:token" element={<Auth />} />
      <Route path="/review/:revtoken" element={<Review />} />
      <Route path="/review" element={<Review />} />
    </Routes>
  );
};

export default CompilerRouter;
