import React, { createContext, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

export type codeContextType = {
  codeContext: any;
  setCodeContext: any;
};

export const File_data = createContext<codeContextType>({
  codeContext: {},
  setCodeContext: () => {},
});

interface ContextProps {
  children?: React.ReactNode;
}

function Context(props: ContextProps) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [codeContext, setCodeContext] = useState();

  React.useEffect(() => {
    console.log("params in context");
    console.log(params);
    console.log(location.pathname);
    let path = location.pathname;
    let isReview = path.includes("review");
    if (isReview) {
    } else {
      if (!params.token) {
        if (localStorage.getItem("token")) {
          navigate(`/auth/${localStorage.getItem("token")}`);
        } else {
          navigate(`/auth/notfound`);
        }
      }
    }
  }, []);

  return (
    <File_data.Provider value={{ codeContext, setCodeContext }}>
      {props.children}
    </File_data.Provider>
  );
}

export default Context;
