import React, { useContext } from "react";
import {
  useSandpack,
  Sandpack,
  SandpackProvider,
  SandpackLayout,
  SandpackCodeEditor,
  SandpackPreview,
  SandpackConsole,
  SandpackTests,
  SandpackFileExplorer,
  SandpackThemeProvider,
  SandpackFiles,
} from "@codesandbox/sandpack-react";
import { nightOwl } from "@codesandbox/sandpack-themes";

import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import moment from "moment/moment";
import axios from "axios";
import FullEditor from "../../components/editor/FullEditor";
import FileExploreCustom from "../../components/editor/FileExploreCustom";
import CodeEditorCustom from "../../components/editor/CodeEditorCustom";
import ModalCenter from "../../components/common/ModalCenter";
import { File_data } from "../../contexts/CodeContext";

import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";
import { submitTask } from "../../api/ApiAssessment";

import { useParams, useNavigate } from "react-router-dom";
import ModalReset from "../../components/common/ModalReset";

const Compiler = () => {
  const navigate = useNavigate();

  const { codeContext, setCodeContext } = useContext(File_data);
  const [data, setData] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isModalReset, setIsModalReset] = React.useState(false);
  const [filesData, setFileData] = React.useState<SandpackFiles>();
  const [showConsole, setShowConsole] = React.useState(false);
  const [state, setState] = React.useState("confirm");

  React.useEffect(() => {
    if (codeContext) {
      if (localStorage.getItem(`${codeContext?.assessment?.uniqe_id}`)) {
        console.log("has data");
        let key: string = codeContext?.assessment?.uniqe_id;
        let data = JSON.parse(
          localStorage.getItem(`${key ? key : "empty"}`) || "{}"
        );
        if (data) {
          setData(data);
        } else {
          if (codeContext?.assessment) {
            setData(JSON.parse(codeContext?.assessment?.baru));
          }
        }
      } else {
        if (codeContext?.assessment?.baru) {
          setData(JSON.parse(codeContext?.assessment?.baru));
        }
      }
    }
  }, []);

  const handleChange = (value: SandpackFiles | string | undefined) => {
    localStorage.removeItem("files");
    localStorage.setItem("files", JSON.stringify(value));
  };

  React.useEffect(() => {
    handleChange(filesData);
    if (filesData) {
      if (codeContext?.assessment?.uniqe_id) {
        localStorage.setItem(
          `${codeContext?.assessment?.uniqe_id}`,
          JSON.stringify(filesData)
        );
      }
    }
  }, [filesData]);

  // React.useEffect(() => {
  //   if (filesData) {
  //     if (codeContext?.assessment?.uniqe_id) {
  //       localStorage.setItem(
  //         `${codeContext?.assessment?.uniqe_id}`,
  //         JSON.stringify(filesData)
  //       );
  //     }
  //   }
  // }, [filesData]);

  const submitResult = async () => {
    setState("loading");
    let data = {
      answer: JSON.stringify(filesData),
      uniqe: codeContext?.assessment?.uniqe_key,
    };

    try {
      const response = await submitTask(data);
      localStorage.removeItem(`${codeContext?.assessment?.uniqe_id}`);
      navigate("/finish");
      setState("confirm");
    } catch (error) {
      navigate("/notverified");

      setState("confirm");

      console.log("%cindex.jsx line:122 err", "color: #007acc;", error);
    }
  };

  const resetCode = () => {
    // codeContext?.assessment?.baru
    if (codeContext?.assessment?.reset) {
      setFileData(JSON.parse(codeContext?.assessment?.reset));
      setData(JSON.parse(codeContext?.assessment?.reset));
    } else {
      setFileData(JSON.parse(codeContext?.assessment?.baru));
      setData(JSON.parse(codeContext?.assessment?.baru));
    }

    setIsModalReset(false);
  };

  if (!data) {
    return (
      <div className="w-[100vw] h-[100vh] bg-black flex justify-center items-center">
        <Lottie
          animationData={Loading}
          loop={true}
          style={{ width: "50%", height: "50%" }}
        />
      </div>
    );
  }

  return (
    <>
      <FullEditor
        provider={{
          files: data,
          template: "react",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", height: "100vh" }}>
          <FileExploreCustom />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                padding: "8px 24px",
                background: "#011627",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h5
                style={{
                  color: "white",
                  maxWidth: "100%",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                {codeContext.assessment.assessment_code} -{" "}
                {codeContext.assessment.name}
              </h5>
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setIsModalOpen(true)}
              >
                Submit
              </button>
            </div>
            <PanelGroup
              autoSaveId="conditional"
              direction="horizontal"
              style={{ width: "100%", height: "100%" }}
            >
              <Panel order={1}>
                {/* <SandpackCodeEditor /> */}
                <CodeEditorCustom
                  setFileData={setFileData}
                  dateLeft={moment(codeContext?.start_date)}
                  autoSubmit={() => {}}
                  resetCode={() => {
                    setIsModalReset(true);
                  }}
                  // stopTime={"test"}
                  // duration={"test"}
                />
                {/* <CodeEditorCustom setFileData={setFileData} /> */}
              </Panel>
              <>
                <PanelResizeHandle
                  style={{ width: "10px", background: "black" }}
                />

                <Panel order={2} defaultSize={60}>
                  {/* <PanelGroup direction="vertical">
          <Panel> */}
                  <div style={{ margin: "10px" }}>
                    <SandpackProvider files={filesData} template="react">
                      <SandpackThemeProvider theme={nightOwl}>
                        <SandpackLayout
                          // style={{ height: "25vh", flexDirection: "column" }}
                          style={{
                            flexDirection: "column",
                            height: "90vh",
                          }}
                        >
                          <button
                            onClick={() => setShowConsole(!showConsole)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded absolute z-20 bottom-1 right-1 px-2 py-1"
                          >
                            {showConsole ? "Hide Console" : "Show Console"}
                          </button>
                          <SandpackPreview
                            // style={{ height: "25vh" }}
                            showNavigator={true}
                            showOpenInCodeSandbox={false}
                          />
                          <SandpackConsole
                            className={`${showConsole ? "block" : "!hidden"}`}
                          />
                        </SandpackLayout>
                      </SandpackThemeProvider>
                    </SandpackProvider>
                  </div>
                </Panel>
              </>
            </PanelGroup>
          </div>
        </div>
      </FullEditor>
      <ModalCenter
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onConfirm={submitResult}
        state={state}
      />
      <ModalReset
        isOpen={isModalReset}
        setIsOpen={setIsModalReset}
        onConfirm={resetCode}
      />
    </>
  );
};

export default Compiler;
