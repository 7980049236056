import React from "react";
import {
  useSandpack,
  Sandpack,
  SandpackProvider,
  SandpackLayout,
  SandpackCodeEditor,
  SandpackPreview,
  SandpackConsole,
  SandpackTests,
  SandpackFileExplorer,
  SandpackThemeProvider,
} from "@codesandbox/sandpack-react";

import codingIDIcon from "../../assets/icons/codingid.png";

type Props = {};

function FileExploreCustom({}: Props) {
  const [showExplorer, setShowExplorer] = React.useState(true);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "25px 16px",
        background: "#36454F",
        width: showExplorer ? "256px" : "50px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          //   maxWidth: "256px",
        }}
      >
        {/* <Image src="/remove.png" height={22} width={80} alt="Coding.ID" /> */}
        {showExplorer && (
          <img src={codingIDIcon} height={12} width={80} alt="Coding.ID" />
        )}

        {/* <button onClick={() => setShowExplorer((prev) => !prev)}>Hide</button> */}
        <button
          onClick={() => setShowExplorer((prev) => !prev)}
          className="bg-transparent text-[#B3FFE5] font-bold p-0 rounded inline-flex items-center"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${showExplorer ? "" : "transform rotate-180"}`}
          >
            <path
              d="M18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10ZM20 10C20 4.48 15.52 -6.78401e-07 10 -4.37114e-07C4.48 -1.95827e-07 -6.78401e-07 4.48 -4.37114e-07 10C-1.95827e-07 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10ZM10 11L14 11L14 9L10 9L10 6L6 10L10 14L10 11Z"
              fill="#F5F8FF"
            />
          </svg>
        </button>
      </div>

      {showExplorer && (
        <SandpackFileExplorer
          style={{ height: "100%", background: "#36454F", padding: 0 }}
        />
      )}
    </div>
  );
}

export default FileExploreCustom;
