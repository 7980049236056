import React from "react";
import {
  useSandpack,
  Sandpack,
  SandpackThemeProvider,
  SandpackProvider,
  SandpackLayout,
  SandpackCodeEditor,
  SandpackPreview,
  SandpackConsole,
  SandpackTests,
} from "@codesandbox/sandpack-react";

import { nightOwl } from "@codesandbox/sandpack-themes";
type Props = {
  children: string | JSX.Element | JSX.Element[];
  provider: any;
  filesData?: any;
};

const FullEditor = (props: Props) => {
  return (
    <SandpackProvider
      template=""
      files={props.filesData}
      {...props?.provider}
      options={{
        classes: {
          "sp-preview-container": "custom-preview-container",
          "sp-file-explorer-list": "sp-file-explorer-list-update",
        },
      }}
    >
      <SandpackThemeProvider theme={nightOwl}>
        {props.children}
      </SandpackThemeProvider>
    </SandpackProvider>
  );
};

export default FullEditor;
