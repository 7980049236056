import APIRequest from "./Axios";

const getTask = (token) => {
  return APIRequest.get(`/assessment/getdatasandpack/${token}`);
};

const reviewSubmittedTask = (token) => {
  return APIRequest.get(`/assessment/getreviewdatasandpack/${token}`);
};

const submitTask = (data) => {
  return APIRequest.post(`/assessment/submitresultsandpack`, data);
};

export { getTask, submitTask, reviewSubmittedTask };
