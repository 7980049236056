import React from "react";
import Lottie from "lottie-react";
import Loading from "./loading.json";

function ModalReset({ isOpen, setIsOpen, onConfirm }) {
  return (
    <div
      className={`fixed  inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full ${
        isOpen ? "block" : "hidden"
      }`}
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
            <svg
              class="h-6 w-6 text-green-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Are you sure to reset your code?
          </h3>
          {/* <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">
              After reset 
            </p>
          </div> */}
          <div className="items-center px-4 py-3">
            <button
              onClick={() => onConfirm()}
              id="ok-btn"
              className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
            >
              OK
            </button>

            <button
              onClick={() => setIsOpen(false)}
              id="ok-btn"
              className="px-4 py-2  text-black text-base font-medium rounded-md w-full shadow-sm border-cyan-200 mt-3"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalReset;
