import React from "react";
import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";
import { useParams, useNavigate } from "react-router-dom";
import { reviewSubmittedTask } from "../../api/ApiAssessment";

import {
  useSandpack,
  Sandpack,
  SandpackProvider,
  SandpackLayout,
  SandpackCodeEditor,
  SandpackPreview,
  SandpackConsole,
  SandpackTests,
  SandpackFileExplorer,
  SandpackThemeProvider,
  SandpackFiles,
} from "@codesandbox/sandpack-react";
import { nightOwl } from "@codesandbox/sandpack-themes";

import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import moment from "moment/moment";

import FullEditor from "../../components/editor/FullEditor";
import FileExploreCustom from "../../components/editor/FileExploreCustom";
import CodeEditorCustom from "../../components/editor/CodeEditorCustom";

type Props = {};

const Review = (props: Props) => {
  const { revtoken } = useParams();
  const navigate = useNavigate();

  const [data, setData] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [filesData, setFileData] = React.useState<SandpackFiles>();
  const [showConsole, setShowConsole] = React.useState(false);
  const [state, setState] = React.useState("loading");

  const [stateData, setStateData] = React.useState<any>();

  const handleChange = (value: SandpackFiles | string | undefined) => {
    localStorage.removeItem("rev-files");
    localStorage.setItem("rev-files", JSON.stringify(value));
  };

  React.useEffect(() => {
    handleChange(filesData);
    if (filesData) {
      if (stateData?.assessment?.uniqe_id) {
        localStorage.setItem(
          `rev-${stateData?.assessment?.uniqe_id}`,
          JSON.stringify(filesData)
        );
      }
    }
  }, [filesData]);

  React.useEffect(() => {
    console.log("revtoken" + revtoken);
    if (revtoken) {
      localStorage.setItem("revtoken", revtoken);
      navigate("/review");
      navigate(0);
    } else {
      let token = localStorage.getItem("revtoken") || "";
      console.log("token review" + token);
      getDataReview(token);
    }
  }, []);

  const getDataReview = async (token: string) => {
    try {
      const response = await reviewSubmittedTask(token);
      const data = response.data;
      //   console.log(data);
      // if (localStorage.getItem(`rev-${data?.assessment?.uniqe_id}`)) {
      //   console.log("has data");
      //   let key: string = `rev-${data?.assessment?.uniqe_id}`;
      //   let dataNew = JSON.parse(
      //     localStorage.getItem(`${key ? key : "empty"}`) || "{}"
      //   );
      //   console.log(dataNew);
      //   if (dataNew) {
      //     setData(dataNew?.assessment?.baru);
      //   } else {
      //     setData(data?.assessment?.baru);
      //   }
      // } else {
      //   setData(data?.assessment?.baru);
      // }

      if (!data?.assessment) {
        navigate("/notverified");
      }

      setData(data?.assessment?.baru);

      setStateData(data);
      //   setFileData(data?.assessment?.baru);

      console.log("%cReview.tsx line:26 data", "color: #007acc;", data);
      setState("idle");
    } catch (error) {
      console.log(error);
      setState("idle");
    }
  };
  //   console.log(stateData);
  if (state == "loading" || !stateData) {
    return (
      <div className="w-[100vw] h-[100vh] bg-black flex justify-center items-center">
        <Lottie
          animationData={Loading}
          loop={true}
          style={{ width: "50%", height: "50%" }}
        />
      </div>
    );
  }

  return (
    <>
      <FullEditor
        provider={{
          files: data,
          template: "react",
        }}
        // filesData={filesData}
      >
        <div style={{ display: "flex", flexDirection: "row", height: "100vh" }}>
          <FileExploreCustom />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                padding: "8px 24px",
                background: "#011627",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h5
                style={{
                  color: "white",
                  maxWidth: "100%",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                {stateData?.assessment?.assessment_code} -{" "}
                {stateData?.assessment?.name}
              </h5>
              {/* <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setIsModalOpen(true)}
              >
                Submit
              </button> */}
            </div>
            <PanelGroup
              autoSaveId="conditional"
              direction="horizontal"
              style={{ width: "100%", height: "100%" }}
            >
              <Panel order={1}>
                {/* <SandpackCodeEditor /> */}
                <CodeEditorCustom
                  setFileData={setFileData}
                  dateLeft={moment(stateData?.start_date)}
                  autoSubmit={() => {}}
                  stopTime={true}
                  duration={stateData?.assessment.timer}
                  // stopTime={"test"}
                  // duration={"test"}
                />
                {/* <CodeEditorCustom setFileData={setFileData} /> */}
              </Panel>
              <>
                <PanelResizeHandle
                  style={{ width: "10px", background: "black" }}
                />

                <Panel order={2} defaultSize={60}>
                  {/* <PanelGroup direction="vertical">
    <Panel> */}
                  <div style={{ margin: "10px" }}>
                    <SandpackProvider files={filesData} template="react">
                      <SandpackThemeProvider theme={nightOwl}>
                        <SandpackLayout
                          // style={{ height: "25vh", flexDirection: "column" }}
                          style={{
                            flexDirection: "column",
                            height: "90vh",
                          }}
                        >
                          <button
                            onClick={() => setShowConsole(!showConsole)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded absolute z-20 bottom-1 right-1 px-2 py-1"
                          >
                            {showConsole ? "Hide Console" : "Show Console"}
                          </button>
                          <SandpackPreview
                            // style={{ height: "25vh" }}
                            showNavigator={true}
                            showOpenInCodeSandbox={false}
                          />
                          <SandpackConsole
                            className={`${showConsole ? "block" : "!hidden"}`}
                          />
                        </SandpackLayout>
                      </SandpackThemeProvider>
                    </SandpackProvider>
                  </div>
                </Panel>
              </>
            </PanelGroup>
          </div>
        </div>
      </FullEditor>
    </>
  );
};

export default Review;
