import React from "react";
import successIcon from "../../assets/icons/finish-icon.png";

type Props = {};

const Finish = (props: Props) => {
  return (
    <div className="flex justify-center items-center w-full h-[100vh] bg-[black] flex-col">
      <img src={successIcon} height={14} width={150} alt="Finish" />
      <h2 className="text-white">Well done!</h2>
      <div>
        <p className="text-white">You’ve submitted assesment, Thank you!</p>
        {/* <Button
          variant="contained"
          onClick={backHandler}
          size="small"
          className={classes.buttonNext}
        >
          <HomeIcon fontSize="small" style={{ marginRight: "5px" }} />
          Back to Home
        </Button> */}
      </div>
    </div>
  );
};

export default Finish;
