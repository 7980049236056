import React from "react";
import Lottie from "lottie-react";
import Loading from "./loading.json";

function ModalCenter({ isOpen, setIsOpen, state = "confirm", onConfirm }) {
  //   const [isOpen, setIsOpen] = React.useState(false);

  const IconState = (state) => {
    let iconstate;
    switch (state) {
      case "loading":
        iconstate = (
          <div className="mx-auto flex items-center justify-center w-auto">
            <Lottie
              animationData={Loading}
              loop={true}
              style={{ width: "50%", height: "50%" }}
            />
          </div>
        );
        break;
      case "confirm":
        iconstate = (
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
            <svg
              class="h-6 w-6 text-green-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </div>
        );
        break;
      case "failed":
        break;
      default:
        break;
    }

    return iconstate;
  };

  const bodyContent = (state) => {
    let content;
    switch (state) {
      case "loading":
        // content = <h3>loading..</h3>;
        break;

      default:
        content = (
          <>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Do you want to submit the answer?
            </h3>
            <div className="mt-2 px-7 py-3">
              <p className="text-sm text-gray-500">
                Once submitted the answer can't be edited
              </p>
            </div>
          </>
        );
        break;
    }
    return content;
  };

  const ConfirmationButton = (state) => {
    let button;
    switch (state) {
      case "loading":
        break;

      default:
        button = (
          <div className="items-center px-4 py-3">
            <button
              onClick={() => onConfirm()}
              id="ok-btn"
              className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
            >
              OK
            </button>

            <button
              onClick={() => setIsOpen(false)}
              id="ok-btn"
              className="px-4 py-2  text-black text-base font-medium rounded-md w-full shadow-sm border-cyan-200 mt-3"
            >
              Cancel
            </button>
          </div>
        );
        break;
    }

    return button;
  };
  return (
    <div
      className={`fixed  inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full ${
        isOpen ? "block" : "hidden"
      }`}
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          {IconState(state)}
          {bodyContent(state)}
          {ConfirmationButton(state)}
        </div>
      </div>
    </div>
  );
}

export default ModalCenter;
