import React from "react";

import {
  useSandpack,
  Sandpack,
  SandpackProvider,
  SandpackLayout,
  SandpackCodeEditor,
  SandpackPreview,
  SandpackConsole,
  SandpackTests,
  useActiveCode,
} from "@codesandbox/sandpack-react";
import { autocompletion, completionKeymap } from "@codemirror/autocomplete";
import { codeFolding } from "@codemirror/language";
import { useCountdown } from "../../hooks/useCountdown";
import moment from "moment/moment";
type Props = {
  setFileData: any;
  stopTime?: any;
  autoSubmit: any;
  dateLeft: any;
  duration?: any;
  resetCode?: () => void;
};

const CodeEditorCustom = (props: Props) => {
  const { code, updateCode } = useActiveCode();
  const { sandpack } = useSandpack();
  const { files, activeFile } = sandpack;
  // const [days, hours, minutes, seconds] = useCountdown(moment("2023-05-09"));
  const [days, hours, minutes, seconds] = useCountdown(props.dateLeft);
  React.useEffect(() => {
    // console.log("file changes");
    // console.log(files);
    let file = files;
    props.setFileData(files);
    // // sandpack.resetAllFiles();
    // // sandpack.editorState;
    // // sandpack.files = file;
    // // sandpack.updateFile(files);
    // console.log(sandpack.files);
    // sandpack.runSandpack();
  }, [files]);

  React.useEffect(() => {
    if (
      days == 0 &&
      hours == 0 &&
      minutes == 0 &&
      seconds == 0 &&
      !props.stopTime
    ) {
      props.autoSubmit();
    }
  }, [seconds]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="bg-[#011627] flex px-3 py-2 justify-between">
        <button
          onClick={() => props.resetCode?.()}
          className="bg-[#3DC3DD] hover:bg-grey text-grey-darkest font-bold p-2 rounded inline-flex items-center"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.66659 10L9.66659 7L5.66659 3.99999V10ZM6.99992 0.333328C3.31992 0.333328 0.333252 3.31999 0.333252 7C0.333252 10.68 3.31992 13.6667 6.99992 13.6667C10.6799 13.6667 13.6666 10.68 13.6666 7C13.6666 3.31999 10.6799 0.333328 6.99992 0.333328ZM6.99992 12.3333C4.05992 12.3333 1.66659 9.93999 1.66659 7C1.66659 4.06 4.05992 1.66666 6.99992 1.66666C9.93992 1.66666 12.3333 4.06 12.3333 7C12.3333 9.93999 9.93992 12.3333 6.99992 12.3333Z"
              fill="#2B2E33"
            />
          </svg>

          <span className="ml-1">Reset Code</span>
        </button>

        <button className="bg-transparent text-[#B3FFE5] font-bold p-2 rounded inline-flex items-center">
          <svg
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5583 0.84166H5.55835V2.50833H10.5583V0.84166ZM7.22502 11.675H8.89168V6.67499H7.22502V11.675ZM13.9167 6.15833L15.1 4.97499C14.7417 4.54999 14.35 4.14999 13.925 3.79999L12.7417 4.98333C11.45 3.94999 9.82502 3.33333 8.05835 3.33333C3.91668 3.33333 0.55835 6.69166 0.55835 10.8333C0.55835 14.975 3.90835 18.3333 8.05835 18.3333C12.2083 18.3333 15.5583 14.975 15.5583 10.8333C15.5583 9.07499 14.9417 7.44999 13.9167 6.15833ZM8.05835 16.675C4.83335 16.675 2.22502 14.0667 2.22502 10.8417C2.22502 7.61666 4.83335 5.00833 8.05835 5.00833C11.2833 5.00833 13.8917 7.61666 13.8917 10.8417C13.8917 14.0667 11.2833 16.675 8.05835 16.675Z"
              fill="#B3FFE5"
            />
          </svg>

          {!props.stopTime && days + hours + minutes + seconds <= 0 && (
            <h3>Time Up</h3>
          )}
          {!props.stopTime && days + hours + minutes + seconds > 0 && (
            <span className="ml-2">
              {days}:{hours}:{minutes}:{seconds}
            </span>
          )}

          {props.stopTime && (
            <span className="ml-2">
              {moment.utc(props.duration * 1000).format("HH:mm:ss")}
            </span>
          )}
        </button>
      </div>
      <SandpackCodeEditor
        showLineNumbers={true}
        showRunButton={true}
        wrapContent={true}
        // showTabs={true}
        closableTabs={true}
        // showInlineErrors={true}
        // style={{ height: "70vh", resize: "both" }}
        // style={{ height: "98vh" }}
        style={{ height: "100%" }}
        // foldGutter={true}
        extensions={[autocompletion(), codeFolding()]}
        // extensionsKeymap={[completionKeymap]}
        {...props}
      />
    </div>
  );
};

export default CodeEditorCustom;
